@charset "UTF-8";
/*=================
    パスワードリセット入力
===================================*/

/* パスワード再発行メール送信画面
 ========================================================================== */
.resetPassMailSend {
  @include form;
}

.resetPassMailSend__title {
  color: $text-white;
}
.resetPassMailSend__panel {
  @include formPanel;
}

/* パスワード再設定画面
 ========================================================================== */
.resetPassFrm {
  @include form;
}


.resetPassFrm__panel {
  @include formPanel;
}

.resetPassFrm__passInjection {
  margin-top: 0;
  margin-bottom: 0;
}