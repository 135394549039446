@charset "UTF-8";

/*=================
    ログイン画面
===================================*/
.loginForm{
  margin: 0px auto;
  margin-top: 100px;
}


i{
  color: #2E2F30;
}


.loginForm__loginPanel{
  @include formPanel();
}