@charset "UTF-8";
@import "theme-color";


html {
  min-height: 100%; /* html要素をウィンドウサイズにフィット */
  //background-color: $background-color;
  background-size:cover; /* 背景画像をhtml要素にフィット */
}

body {
  color: $text-black;
  margin: 0;
  padding: 0;
  line-height: 1.4;
  font-family: 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-size: 0.9em;
}

//iPhoneのみに適用
@media screen and (max-device-width: 480px) {
  body {
    //javascriptのclickイベントが効かないため
    cursor: pointer;
    //なにもないところをタップすると全体がグレーになるのを削除
    -webkit-tap-highlight-color:rgba(0,0,0,0);
  }
}


//Google Chromeで日本語変換中に文字色が白くなるのを防ぐ
input::selection,
textarea::selection {
  color: #000;
}

//uikitはdefaultで15pxのため
p,
ul,
ol,
dl,
blockquote,
pre,
address,
fieldset,
figure {
  margin: 0 0 5px 0;
}


//リストの点消し
li {
  list-style: none;
}
