.newsSection {
  padding-bottom: 10px;
}

.newsSection__list {
  a {
    text-decoration: none;
  }
}

.newsSection__adminName {
  margin-right: 9px;
}