$theme-color: #edb;
$theme-complementary-color-first: #ebc;
$theme-complementary-color-second: #bed;
$theme-complementary-color-third: #bce;
$theme-color-light: #fed;
$theme-color-dark: #b96;
$text-white: #FFFFFF;
$color-white: #FFFFFF;
$text-black: #555459;
$text-gray: #7f7f7f;
