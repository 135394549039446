@charset "UTF-8";

/*=================
    キャスト一覧画面
===================================*/
.castSection {
}

@media screen and (max-width: 320px) {
  .castSection__note {
    font-size: 10px;
  }
}

.castSection__tr--deleted {
  background-color: #DCDCDC;
}
