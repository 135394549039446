@charset "UTF-8";

/* ========================================================================
   アプリから見る店舗からのお知らせ
 ========================================================================== */
.appNewsContainer {
  p {
    margin: 0 0 2px 0;
  }

  padding-bottom: 10px;
}

.appNewsContainer__list {
  position: relative;
  a {
    text-decoration: none;
  }
}

.appNewsContainer__link {
  display: block;
  padding: 10px 0.5% 10px 2.5%;
}

.appNewsContainer__title {
  @include appTextSize();
}

.appNewsContainer__body {
  @include appTextSize();
}

p.appNewsContainer__adminName {
  @include appTextMuted();
  margin-right: 9px;
}

.appNewsContainer__time {
  @include appTextMuted();
}

.new_ribbon {
  position: absolute;
  top: 4px;
  right: 4px;
  display: inline-block;
  padding: 5px 8px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  background-color: #fe0100;
  z-index: 10;
  border-radius: 12px
}