@charset "UTF-8";
@import "../base/theme-color";

@mixin navbarIcon() {
  font-size: 18px;
}


@mixin accentBtn() {
  color: $text-black;
  border-radius: 4px;
  text-shadow: 0px 0px 0px $text-black;
  background-color: $theme-complementary-color-second;
  box-shadow: 0 1px 0 $theme-complementary-color-second;
  -webkit-box-shadow: 0 1px 0 $theme-complementary-color-second;
  -moz-box-shadow: 0 1px 0 $theme-complementary-color-second;
  &:hover,
  &:focus {
    background-color: darken($theme-complementary-color-second, 3%);
    color: $text-black;
    text-shadow: 0px 0px 0px $text-black;
    box-shadow: 0px 0px 10px 1px $theme-complementary-color-second;
    -webkit-box-shadow: 0px 0px 10px 1px $theme-complementary-color-second;
    -moz-box-shadow: 0px 0px 10px 1px $theme-complementary-color-second;
  }
}

@mixin btnMargin() {
  margin-top: 20px;
}

@mixin appTextSize() {
  font-size: 14px;
}

@mixin appTextMuted() {
  color: #777;
  font-size: 10px;
}

@mixin form() {
  max-width: 400px;
  margin: 0px auto;
  margin-top: 100px;
}

@mixin formPanel() {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  margin: 30px auto;
  width: 250px;
  padding: 40px 10px;
  height: auto;
}
