@charset "UTF-8";

/* ========================================================================
   ナビゲーションバー
 ========================================================================== */
.navbarSection {
  background-color: $theme-color;
  font-size: 14px;
}

@media screen and (max-width: 320px) {
  nav.navbarSection>div.navbarSection__container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.navbarSection__logo {
  max-width: 100px;
  margin-bottom: 5px;
}

.uk-navbar-nav > li > a.navbarSection__logoLink {
  padding: 0;
}

.navbarSection__iconInfo {
  @include navbarIcon();
  margin-left: 16px;
}

.navbarSection__iconCast {
  @include navbarIcon();
  margin-left: 10px;
}

.navbarSection__iconAdmin {
  @include navbarIcon();
  margin-left: 16px;
}

.navbarSection__iconMenu {
  @include navbarIcon();
  margin-left: 9px;
}

/* Active */
.uk-navbar-nav > li.navbarSection__menu--active > a {
  background-color: $theme-color-light;
  border-left-color: rgba(0, 0, 0, 0.1);
  border-right-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.1);
}