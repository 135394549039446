@charset "UTF-8";
html {
  min-height: 100%;
  /* html要素をウィンドウサイズにフィット */
  background-size: cover;
  /* 背景画像をhtml要素にフィット */
}

body {
  color: #555459;
  margin: 0;
  padding: 0;
  line-height: 1.4;
  font-family: 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-size: 0.9em;
}

@media screen and (max-device-width: 480px) {
  body {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
}

input::selection,
textarea::selection {
  color: #000;
}

p,
ul,
ol,
dl,
blockquote,
pre,
address,
fieldset,
figure {
  margin: 0 0 5px 0;
}

li {
  list-style: none;
}

/* ========================================================================
   ナビゲーションバー
 ========================================================================== */
.navbarSection {
  background-color: #edb;
  font-size: 14px;
}

@media screen and (max-width: 320px) {
  nav.navbarSection > div.navbarSection__container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.navbarSection__logo {
  max-width: 100px;
  margin-bottom: 5px;
}

.uk-navbar-nav > li > a.navbarSection__logoLink {
  padding: 0;
}

.navbarSection__iconInfo {
  font-size: 18px;
  margin-left: 16px;
}

.navbarSection__iconCast {
  font-size: 18px;
  margin-left: 10px;
}

.navbarSection__iconAdmin {
  font-size: 18px;
  margin-left: 16px;
}

.navbarSection__iconMenu {
  font-size: 18px;
  margin-left: 9px;
}

/* Active */
.uk-navbar-nav > li.navbarSection__menu--active > a {
  background-color: #fed;
  border-left-color: rgba(0, 0, 0, 0.1);
  border-right-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.1);
}

/*=================
    キャスト一覧画面
===================================*/
@media screen and (max-width: 320px) {
  .castSection__note {
    font-size: 10px;
  }
}

.castSection__tr--deleted {
  background-color: #DCDCDC;
}

.newsSection {
  padding-bottom: 10px;
}

.newsSection__list a {
  text-decoration: none;
}

.newsSection__adminName {
  margin-right: 9px;
}

/* ========================================================================
   アプリから見る店舗からのお知らせ
 ========================================================================== */
.appNewsContainer {
  padding-bottom: 10px;
}

.appNewsContainer p {
  margin: 0 0 2px 0;
}

.appNewsContainer__list {
  position: relative;
}

.appNewsContainer__list a {
  text-decoration: none;
}

.appNewsContainer__link {
  display: block;
  padding: 10px 0.5% 10px 2.5%;
}

.appNewsContainer__title {
  font-size: 14px;
}

.appNewsContainer__body {
  font-size: 14px;
}

p.appNewsContainer__adminName {
  color: #777;
  font-size: 10px;
  margin-right: 9px;
}

.appNewsContainer__time {
  color: #777;
  font-size: 10px;
}

.new_ribbon {
  position: absolute;
  top: 4px;
  right: 4px;
  display: inline-block;
  padding: 5px 8px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  background-color: #fe0100;
  z-index: 10;
  border-radius: 12px;
}

/*=================
    ログイン画面
===================================*/
.loginForm {
  margin: 0px auto;
  margin-top: 100px;
}

i {
  color: #2E2F30;
}

.loginForm__loginPanel {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  margin: 30px auto;
  width: 250px;
  padding: 40px 10px;
  height: auto;
}

/*=================
    パスワードリセット入力
===================================*/
/* パスワード再発行メール送信画面
 ========================================================================== */
.resetPassMailSend {
  max-width: 400px;
  margin: 0px auto;
  margin-top: 100px;
}

.resetPassMailSend__title {
  color: #FFFFFF;
}

.resetPassMailSend__panel {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  margin: 30px auto;
  width: 250px;
  padding: 40px 10px;
  height: auto;
}

/* パスワード再設定画面
 ========================================================================== */
.resetPassFrm {
  max-width: 400px;
  margin: 0px auto;
  margin-top: 100px;
}

.resetPassFrm__panel {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  margin: 30px auto;
  width: 250px;
  padding: 40px 10px;
  height: auto;
}

.resetPassFrm__passInjection {
  margin-top: 0;
  margin-bottom: 0;
}

input.submitBtn,
button.submitBtn,
a.submitBtn {
  color: #555459;
  border-radius: 4px;
  text-shadow: 0px 0px 0px #555459;
  background-color: #bed;
  box-shadow: 0 1px 0 #bed;
  -webkit-box-shadow: 0 1px 0 #bed;
  -moz-box-shadow: 0 1px 0 #bed;
  margin-top: 20px;
}

input.submitBtn:hover, input.submitBtn:focus,
button.submitBtn:hover,
button.submitBtn:focus,
a.submitBtn:hover,
a.submitBtn:focus {
  background-color: #afebd7;
  color: #555459;
  text-shadow: 0px 0px 0px #555459;
  box-shadow: 0px 0px 10px 1px #bed;
  -webkit-box-shadow: 0px 0px 10px 1px #bed;
  -moz-box-shadow: 0px 0px 10px 1px #bed;
}

.exitBtn {
  margin-top: 20px;
}

.textThemeColor {
  color: #b96;
}

.textBlack {
  color: #555459;
}

.textMedium {
  font-size: 16px;
}

/* ログイン画面背景に使用
 ========================================================================== */
.backGround {
  min-height: 100%;
  /* html要素をウィンドウサイズにフィット */
  background: linear-gradient(-135deg, #d3bc9b, #bb9966, #937241);
  background-size: cover;
  /* 背景画像をhtml要素にフィット */
}
