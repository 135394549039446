@charset "UTF-8";

input.submitBtn,
button.submitBtn,
a.submitBtn {
  @include accentBtn();
  @include btnMargin();
}

.exitBtn {
  @include btnMargin();
}

.textThemeColor {
  color: $theme-color-dark;
}

.textBlack {
  color: $text-black;
}

.textMedium {
  font-size: 16px;
}

/* ログイン画面背景に使用
 ========================================================================== */
.backGround {
  min-height: 100%; /* html要素をウィンドウサイズにフィット */
  background: linear-gradient(-135deg, #d3bc9b, #bb9966, #937241);
  background-size:cover; /* 背景画像をhtml要素にフィット */
}